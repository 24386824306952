import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import {
  parseSingleOrganizationAssetRiskTasksForFE,
  parseAllOrganizationAssetRiskTasksForFE,
  parseCreateOrganizationAssetRiskFormInput,
  parseUpdateOrganizationAssetRiskFormInput,
} from './organizationAssetRiskTasks.parsers';
import type OrganizationAssetRiskTasksApi from './organizationAssetRiskTasks.types';

const endpoint = 'api/v1/organization/asset_risk_tasks/';
const endpointGeneral = 'api/v1/asset_risk_tasks/';

export const legacyOrganizationAssetRiskTasksApi = {
  getAllOrganizationAssetRiskTasks: (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, params),
    method: METHOD.GET,
  }),
};

const organizationAssetRiskTasksApi: OrganizationAssetRiskTasksApi = {
  getAllOrganizationAssetRiskTasks: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllOrganizationAssetRiskTasksForFE(data);
  },
  getSingleOrganizationAssetRiskTasks: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleOrganizationAssetRiskTasksForFE(data);
  },
  removeOrganizationAssetRiskTask: id => axios.delete(`${endpointGeneral}${id}/`),
  createOrganizationAssetRiskTask: data => axios.post(endpointGeneral, parseCreateOrganizationAssetRiskFormInput(data)),
  updateOrganizationAssetRiskTask: ({ id, data }) =>
    axios.patch(`${endpointGeneral}${id}/`, parseUpdateOrganizationAssetRiskFormInput(data)),
};

export default organizationAssetRiskTasksApi;
