import { format } from 'date-fns';

import DATE_FORMAT from '../../constants/DATE_FORMAT';
import { parseUserForFE } from '../_parsers/parseUser';

import {
  OrganizationAssetRiskTasks,
  OrganizationAssetRiskTasksDTO,
  RiskDTO,
  Risk,
  CreateInputDto,
  CreateInputForm,
  UpdateInputDto,
  UpdateInputForm,
  OrganizationAssetRiskTasksDTODetail,
  OrganizationAssetRiskTasksDetail,
} from './organizationAssetRiskTasks.types';

const parseRiskForFE = (raw: RiskDTO): Risk => ({
  id: raw.id,
  name: raw.name,
  description: raw.description,
  consequence: raw.consequence,
  probability: raw.probability,
  riskQuadrant: raw.risk_quadrant,
  affectIndividuals: raw.affect_individuals,
  decision: raw.decision,
  place: raw.place,
  primaryConsequence: raw.primary_consequence,
  riskAnalysis: {
    id: raw.risk_analysis.id,
    createdAt: new Date(raw.risk_analysis.created_at),
    updatedAt: new Date(raw.risk_analysis.updated_at),
    date: new Date(raw.risk_analysis.date),
    status: raw.risk_analysis.status,
    asset: raw.risk_analysis.asset,
  },
});

export const parseAllOrganizationAssetRiskTasksForFE = (raw: OrganizationAssetRiskTasksDTO[]): OrganizationAssetRiskTasks[] =>
  raw.map(task => ({
    id: task.id,
    comment: task.comment,
    assignedTo: parseUserForFE(task.assigned_to),
    dueDate: new Date(task.due_date),
    lastStatusDate: new Date(task.last_status_date),
    plannedAction: task.planned_action,
    description: task.description,
    status: task.status,
    risk: parseRiskForFE(task.risk),
  }));

export const parseSingleOrganizationAssetRiskTasksForFE = (raw: OrganizationAssetRiskTasksDTODetail): OrganizationAssetRiskTasksDetail => ({
  id: raw.id,
  comment: raw.comment,
  assignedTo: parseUserForFE(raw.assigned_to),
  dueDate: new Date(raw.due_date),
  lastStatusDate: new Date(raw.last_status_date),
  plannedAction: raw.planned_action,
  description: raw.description,
  status: raw.status,
  risk: parseRiskForFE(raw.risk),
});

export const parseCreateOrganizationAssetRiskFormInput = (formData: CreateInputForm): CreateInputDto => ({
  risk: formData.risk,
  planned_action: formData.plannedAction,
  assigned_to: formData.assignedTo[0].key,
  due_date: format(formData.dueDate, DATE_FORMAT.defaultDate),
  status: formData.status,
  description: formData.description,
  comment: formData.comment,
});

export const parseUpdateOrganizationAssetRiskFormInput = (formData: UpdateInputForm): UpdateInputDto => ({
  comment: formData.comment,
  status: formData.status,
});
