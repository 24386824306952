import React, { useEffect, useMemo } from 'react';

import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import organizationAssetRiskTasksApi from 'config/api/organizationAssetRiskTasks/organizationAssetRiskTasks';
import { CreateInputForm } from 'config/api/organizationAssetRiskTasks/organizationAssetRiskTasks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import TASK_STATUSES_SELECT from 'config/dictionaries/TASK_STATUSES_SELECT';
import EVENTS from 'config/events/pubsub';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';
import { Id } from 'types/Id';

import DATE_FORMAT from '../../../config/constants/DATE_FORMAT';
import FormAutocomplete from '../../FormAutocomplete';
import FormDateInput from '../../FormDateInput';
import FormSelect from '../../FormSelect';
import FormTextInput from '../../FormTextInput';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './AssetRiskTaskDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  previewMode: boolean;
  taskId: Id | null;
  riskId: Id | null;
};

type FormType = Omit<CreateInputForm, 'risk'>;

const AssetRiskTaskDialog: React.FC<Props> = ({ open, onClose, taskId, riskId, previewMode }) => {
  const FORM_ID = useMemo(() => `AssetRiskTask-${taskId}`, [taskId]);
  const isEdit = !!taskId;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: initialData, isLoading } = useQuery(
    [QUERY_KEYS.GET_SINGLE_ORGANIZATION_ASSET_RISK_TASK, taskId],
    () => organizationAssetRiskTasksApi.getSingleOrganizationAssetRiskTasks(taskId as Id),
    {
      enabled: !!taskId,
    },
  );

  const createMutation = useMutation(organizationAssetRiskTasksApi.createOrganizationAssetRiskTask);
  const updateMutation = useMutation(organizationAssetRiskTasksApi.updateOrganizationAssetRiskTask);

  const onSubmit = async (formValues: FormType) => {
    const isUpdate = !!taskId;
    if (isUpdate) {
      await updateMutation.mutateAsync({ id: taskId as Id, data: { comment: formValues.comment, status: formValues.status } });
    } else {
      await createMutation.mutateAsync({ ...formValues, risk: riskId as Id });
    }
    enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    PubSub.publish(EVENTS.RISK_ANALYSIS_TASK_UPDATED);
    onClose();
  };

  const form = useFormik<FormType>({
    initialValues: {
      comment: initialData?.comment || '',
      dueDate: initialData?.dueDate || new Date(),
      plannedAction: initialData?.plannedAction || '',
      status: initialData?.status || 'not_started',
      description: initialData?.description || '',
      assignedTo: initialData?.assignedTo ? [{ key: initialData.assignedTo.id, fullName: initialData.assignedTo.profile.fullName }] : [],
    },
    onSubmit,
  });

  useEffect(() => {
    if (initialData) {
      form.setValues({
        comment: initialData.comment,
        status: initialData.status,
        dueDate: initialData.dueDate,
        plannedAction: initialData.plannedAction,
        description: initialData.description,
        assignedTo: initialData.assignedTo ? [{ key: initialData.assignedTo.id, fullName: initialData.assignedTo.profile.fullName }] : [],
      });
    }
  }, [initialData]);

  const title = useMemo(() => {
    if (previewMode) {
      return t(asset_risk_messages.risk_task_dialog.preview_title);
    }
    return taskId ? t(asset_risk_messages.risk_task_dialog.edit_title) : t(asset_risk_messages.risk_task_dialog.create_title);
  }, [previewMode, taskId, t]);

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        previewMode ? (
          <CenteredGrid withoutPadding>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
              {t(general_messages.close)}
            </ColoredButton>
          </CenteredGrid>
        ) : (
          <ButtonsGrid>
            {/* @ts-ignore */}
            <ColoredButton customColor='none' onClick={onClose} variant='outlined'>
              {t(general_messages.cancel)}
            </ColoredButton>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
              {t(general_messages.save)}
            </ColoredButton>
          </ButtonsGrid>
        )
      }
      noPadding
      onClose={onClose}
      open={open}
      sending={createMutation.isLoading || updateMutation.isLoading}
      title={title}
    >
      {isLoading ? (
        <CenteredGrid>
          <CircularProgress />
        </CenteredGrid>
      ) : (
        <form id={FORM_ID} onSubmit={form.handleSubmit}>
          <CenteredGrid gridGap={3}>
            {previewMode && initialData ? (
              <>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.description_label)}</Typography>
                  <Typography>{initialData?.description || t(general_messages.no_data)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.status_label)}</Typography>
                  <Typography>{t(asset_risk_messages.risk_task_status[initialData?.status])}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.due_date_label)}</Typography>
                  <Typography>{format(initialData?.dueDate, DATE_FORMAT.defaultDate)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.planned_action_label)}</Typography>
                  <Typography>{initialData?.plannedAction || t(general_messages.no_data)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.assigned_to_label)}</Typography>
                  <Typography>{`${initialData?.assignedTo.profile.fullName} - ${initialData?.assignedTo.profile.email}`}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.comment_label)}</Typography>
                  <Typography>{initialData?.comment || t(general_messages.no_data)}</Typography>
                </div>
              </>
            ) : (
              <>
                <FormTextInput
                  disabled={isEdit}
                  formik={form}
                  id='description'
                  label={t(asset_risk_messages.risk_task_dialog.description_label)}
                  multiline
                  rows={3}
                />
                <FormSelect
                  formik={form}
                  id='status'
                  label={t(asset_risk_messages.risk_task_dialog.status_label)}
                  options={TASK_STATUSES_SELECT}
                />
                <FormDateInput
                  dateTime={false}
                  disabled={isEdit}
                  formik={form}
                  id='dueDate'
                  label={t(asset_risk_messages.risk_task_dialog.due_date_label)}
                />
                <FormTextInput
                  disabled={isEdit}
                  formik={form}
                  id='plannedAction'
                  label={t(asset_risk_messages.risk_task_dialog.planned_action_label)}
                />
                {isEdit ? (
                  <TextField
                    disabled
                    label={t(asset_risk_messages.risk_task_dialog.assigned_to_label)}
                    value={initialData?.assignedTo.profile.fullName}
                    variant='outlined'
                  />
                ) : (
                  <FormAutocomplete
                    apiCallParamsGetter={coworkerParamsGetter}
                    customizeLabel={coworkerResultComponent}
                    formik={form}
                    id='assignedTo'
                    label={t(asset_risk_messages.risk_task_dialog.assigned_to_label)}
                    multiple={false}
                    optionsParser={coworkerOptionsParser}
                  />
                )}
                <FormTextInput
                  formik={form}
                  id='comment'
                  label={t(asset_risk_messages.risk_task_dialog.comment_label)}
                  multiline
                  rows={3}
                />
              </>
            )}
          </CenteredGrid>
        </form>
      )}
    </DialogGrid>
  );
};

export default AssetRiskTaskDialog;
